'use client';

import { useRef } from 'react';
import ProgramStyleCard from './style-card';

export default function ProgramStylesGrid() {
  const gridRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={gridRef}
      className="relative grid grid-cols-2 md:grid-cols-3 gap-12 grid-rows-[1fr_1fr_1fr_1fr] md:grid-rows-[1fr_1fr_1fr]"
    >
      <ProgramStyleCard gridRef={gridRef} label="Homeschool" />
      <ProgramStyleCard gridRef={gridRef} label="Private School" />
      <ProgramStyleCard gridRef={gridRef} label="Public School" />
      <ProgramStyleCard gridRef={gridRef} label="Unschool" />
      <div className="stack p-6">
        <div className="text-4xl lg:text-6xl text-center font-semibold">
          Students aren't standard
        </div>
      </div>
      <ProgramStyleCard gridRef={gridRef} label="Microschool" />
      <ProgramStyleCard gridRef={gridRef} label="Co-op" />
      <ProgramStyleCard
        gridRef={gridRef}
        label="Self-Directed"
        className="hidden md:flex"
      />
      <ProgramStyleCard gridRef={gridRef} label="Blended" />
    </div>
  );
}
